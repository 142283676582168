@import '../../assets/css/mixins.scss';

.new-password-page {
  @include login-page;

  .container {
    display: flex;
    flex-direction: column;

    .error-message-wrapper {
      margin-top: -26px;
    }

    .bottom-container {
      margin-top: 25px;
    }
  }
}
