@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.basic-button {
  @include flex-row-screen-center;
  color: $color-dark-blue;
  cursor: pointer;
  border: 1px solid $color-dark-blue;
  border-radius: 50px;
  height: 25px;
  padding: 8px 8px 8px 12px;
  white-space: nowrap;
  .text {
    line-height: 22px;
    font-size: 16px;
    font-weight: 400;
    margin-left: 7px;
    margin-right: 7px;
  }
  &.black {
    color: $color-white;
    background-color: $color-dark-blue;
  }
  &.blue {
    color: $color-white;
    background-color: $color-blue-filter;
    border: 1px solid $color-blue-filter;
  }
  &.white {
    color: $color-dark-blue;
    background-color: $color-white;
  }
  &.red {
    color: $color-red-pink;
    background-color: $color-white;
    border: 1px solid $color-red-pink;
    width: 70px;
  }
}
