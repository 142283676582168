@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.text-field {
  @include flex-column-nowrap;
  margin-bottom: 26px;
  max-width: 322px;
  .field-label {
    color: $color-dark-blue;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    min-height: 25px;
    max-width: 140px;
  }
  .field-input {
    background-color: $color-white;
    border: 1px solid $color-gray-silver;
    box-sizing: border-box;
    font-family: $app-font-name;
    height: 40px;
    padding: 8px;
    width: 322px;
    &:-webkit-autofill {
      @include input-autofull;
    }
    &:-webkit-autofill:hover {
      @include input-autofull;
    }
    &:-webkit-autofill:focus {
      @include input-autofull;
    }
    &::placeholder {
      color: $color-gray-silver;
      font-size: 15px;
    }
    &:focus {
      background-color: $color-white-cultured;
      border: 1px solid $color-dark-blue;
      outline: none;
    }
    &.error {
      border: 1px solid $color-red-caramine !important;
    }
    &.disabled {
      border: 1px solid $color-dark-red-shade;
      box-sizing: border-box;
      color: $color-dark-blue;
      cursor: not-allowed;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      opacity: 0.5;
    }
  }

  .password-wrapper {
    position: relative;
    z-index: 1;
    height: 40px;
    .password-eye {
      cursor: pointer;
      position: relative;
      z-index: 10;
      bottom: 30px;
      left: 290px;
    }
  }
}
