@import '../../../../../assets/css/colors.scss';

.apply-button{
  align-items: center;
  background: $color-blue-filter;
  border-radius: 25px;
  color: $color-white;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
}