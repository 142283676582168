@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
.table-buttons {
    @include flex-row-screen-center;
    position: relative;
    .elipsis-icon-action-list {
        @include flex-column-center;
        cursor: default;
        margin-left: 26px;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        &:hover {
            background: $color-gray-light;
        }
        &:active {
            background: $color-gray-light;
        }
    }
}

.dropdown-item {
    padding: 12px 32px 12px 0px;
    margin: 0;
    font-weight: normal;
    width: 126px;
    z-index: 7;
    text-align: end;
    cursor: pointer;
    &:hover {
        background: $color-gray-light;
    }
}

.dropdown-item-green {
    color: $color-green-success;
}

.dropdown-item-red {
    color: $color-red-caramine;
}