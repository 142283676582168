@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.request-edits {
  .approval-header {
    height: 118px;
  }

  .content-modal-wrapper {
    display: flex;
    justify-content: center;
    overflow-y: auto;
    max-height: 542px;

    .left-section-wrapper {
      display: flex;
      flex-direction: column;
      padding: 25px;
      width: 50%;
    }

    .right-section-wrapper {
      border-left: 1px solid rgba($color: $color-black, $alpha: 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 25px;
      width: 50%;

      &.sending-request {
        align-items: center !important;
        border-left: none;
        justify-content: center !important;
      }
    }
  }
}
