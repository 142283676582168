@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.confirmation-popup {
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  right: calc(50% - (428px / 2));
  border-radius: 8px;
  width: 428px;
  height: 54px;
  transition: opacity 0.2s;
  opacity: 1;
  &:hover {
    opacity: 0.9;
  }
  .popup-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    flex-direction: row;
    .text {
      margin-left: 12px;
      color: $color-blue-tabs;
      font-weight: 600;
    }
    .action {
      color: $color-blue-filter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-left: 31px;
    }
  }
}
.confirmation-popup-success {
  background-color: $color-green-narvik;
  border: 1px solid $color-green-success;
}
.confirmation-popup-error {
  background-color: $color-red-linen;
  border: 1px solid $color-red-caramine;
}
.confirmation-popup-warning {
  background-color: $color-orange-linen;
  border: 1px solid $color-orange-tangerine;
}
.confirmation-popup-info {
  background-color: $color-white-cultured;
  border: 1px solid $color-gray-tuna;
  margin-top: 16px;
  top: 0;
}
