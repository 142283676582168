@import '../../assets/css/colors.scss';

.dropzone-div {
  align-items: center;
  background: $color-white;
  border-radius: 13px;
  border: 1px dashed $color-dark-red-shade;
  display: flex;
  justify-content: center;
  max-height: 153px;
  max-width: 264px;
  padding: 20px;

  .dropzone-content {
    align-items: center;
    display: flex;
    flex-direction: column;

    .text-description {
      color: $color-black-dark;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      .select-word {
        color: $color-blue-link;
        text-decoration-line: underline;
      }
    }
  }
}
