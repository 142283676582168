@import '../../../../assets/css/mixins.scss';

.form-wrapper {
  @include flex-column-center;

  .error-message-wrapper {
    margin-top: -26px;
  }

  .select-component {
    margin-bottom: 26px;
  }

  .form-footer {
    @include flex-row-center-space-between;

    &.center {
      justify-content: center;
    }
  }
}
