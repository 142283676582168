@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.form-wrapper {
  align-items: center;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  isolation: isolate;
  padding: 23px;

  .top-container {
    align-items: center;
    align-self: flex-end;
    display: flex;
    height: 20px;

    .close-button {
      cursor: pointer;
      width: 14px;
      height: 14px;
      background: url('../../assets/icons/close.svg') no-repeat center;
    }
  }

  .form-header {
    @include flex-column-center;
    margin-top: 20px;

    .title {
      color: $color-black-dark;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      height: 32px;
      line-height: 36px;
      margin-top: 13px;
    }

    .description {
      color: $color-gray-description-modal;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 8px;
    }
  }
}
