@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.container-comments {
  max-height: 400px;
  overflow-y: auto;
}
.container-reply {
  padding-left: 30px;
}
.no-found {
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
  overflow: hidden;
  width: 400px;
}
