@import '../../assets/css/colors.scss';

.verification-panel {
  padding: 35px 32px 0 32px;

  .panel-title {
    font-size: 20px;
    font-weight: 600;
    color: $color-dark-blue;
    margin-bottom: 20px;
  }

  > div {
    margin-top: 24px;
  }
}
