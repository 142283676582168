@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.request-edits {
  .content-section {
    height: 300px;
    margin-bottom: 10px;

    .panel-title {
      font-size: 20px;
      font-weight: 600;
      color: $color-dark-blue;
      margin-bottom: 10px;
      margin-top: 20px;
    }
  }

  .approval-footer {
    margin-top: 60px;
  }
}
