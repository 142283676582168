@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

.reply-area-comment-wrapper {
  > .text-area-field {
    height: 200px;
    max-height: 250px;

    .text-area-field-input {
      height: 100%;
    }
  }
}

.field {
  border: 1px solid $color-gray-silver-dark;
  font-size: 16px;
  font-style: normal;
  height: 253px;
  line-height: 24px;
  margin: 10px;
  padding: 10px;
  width: 355px;
}
.container-buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.button-cancel {
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
}
