@import '../../assets/css/colors.scss';

.dropdown-item {
  cursor: pointer;
  font-weight: normal;
  margin: 0;
  padding: 12px 16px 12px 16px;
  text-align: center;
  width: 126px;
  z-index: 7;

  &.black-text {
    color: $color-black;
  }
  &.green-text {
    color: $color-green-success;
  }
  &.red-text {
    color: $color-red-caramine;
  }

  &:hover {
    background: $color-gray-light;
  }
}
