@import '../../../../../assets/css/colors.scss';
@import '../../../../../assets/css/mixins.scss';
@import '../../../../../assets/css/variables.scss';

.tag-filter{

  align-items: center;
  background: $color-gray-light;
  border: 1px solid $color-gray-light;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex: none;
  flex-direction: row;
  gap: 4px;
  height: 40px;
  justify-content: center;
  margin-bottom: 16px;
  margin-right: 8px;
  order: 0;
  padding: 8px 16px;

  .text{
    align-items: center;
    color: $color-gray-silver;
    display: flex;
    font-family: $app-font-name;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.selected{

  border: 1px solid $color-blue-filter;

  .text{
    color: $color-blue-filter;
  }
}