@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.verification-row {
  @include flex-row-center;
  margin-bottom: 24px;
  max-height: 360px;
  width: 100%;

  .row-label {
    color: $color-gray-silver;
    font-size: 16px;
    font-weight: 400;
    font-weight: 400;
    line-height: 24px;
    margin-right: 20px;
    width: 140px;
  }
  .row-value {
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 24px;
  }
  .row-value-long {
    height: 250px;
    margin-left: 108px;
  }
}

.communities-wrapper-custom {
  display: inherit;
}

.emails {
  .row-value {
    text-decoration-line: underline;
  }
}
