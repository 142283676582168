@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.error-message-wrapper {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 0 0;
  .error-text {
    color: $color-red-caramine;
    font-size: 14px;
  }
  .icon {
    height: 12px;
    margin-right: 8px;
    width: 12px;
  }
}
