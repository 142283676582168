@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.reports-page {
    @include flex-row-start-center;
    overflow: hidden;
    overflow-x: hidden;
    background-color: $color-gray-alabaster;

    .page-content {
        @include page-content;

        .table-fixed-head {
            margin-top: 38px;
        }
    }
}