@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.left-menu-toggle {
  align-items: center;
  background-color: $color-gray-light;
  border-radius: 23px;
  cursor: pointer;
  display: flex;
  height: 22px;
  justify-content: center;
  margin-top: 6px;
  position: absolute;
  right: -11px;
  text-align: center;
  width: 22px;

  .left-menu-toggle-icon-left {
    transform: rotate(0deg);
  }
  .left-menu-toggle-icon-right {
    transform: rotate(180deg);
  }
  
  &:hover{
    background-color: $color-black;
    path{
      stroke: $color-white;
    }
  }
}