@import '../../assets/css/colors.scss';

.input-search-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 32px;
  max-width: 400px;

  .form {
    border-radius: 24px;
    display: flex;
    flex-direction: column;

    input {
      &[type='text'] {
        background: transparent;
        border-radius: inherit;
        border: 1px solid transparent;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }

      &[type='text']::placeholder {
        color: $color-gray-input-search;
      }

      &[type='text']:focus {
        outline: none;
      }

      &.input-search {
        background: transparent no-repeat 12px center;
        border: 1px solid $color-gray-input-search;
        padding: 10px 10px 7px 45px;
        width: 100%;
      }
    }

    .text-label {
      color: $color-gray-input-search;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      margin-left: 8px;
    }
  }
}
