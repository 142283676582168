@import '../../../../assets/css/colors.scss';

.reported-tag-component {
  width: 110px;
  height: 40px;
  background: $color-pink;
  background: url('../../../../assets/icons/red-right-arrow.svg') no-repeat
    center;
  .tag-text {
    color: $color-white;
    font-size: 10px;
    font-weight: 700;
  }
}
