@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';

.hours {
  color: $color-gray-silver;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin-right: 5px;
}

.like {
  color: $color-gray-silver;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  margin-right: 5px;
}
.reply {
  color: $color-blue-reply;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  margin-right: 5px;
}
