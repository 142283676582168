@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.tab-component {
  display: inline-block;
  position: relative;
  list-style: none;
  cursor: pointer;
  margin-right: 58px;
  .tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;

    &.border {
      border-bottom: 2px solid $color-blue-filter;
    }
    .tab-label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-blue-tabs !important;
      margin-left: 7.44px;
    }
  }
}
