@import '../../../assets/css/colors.scss';

.applied-filter{
  align-items: center;
  background: $color-white;
  border: 1px solid $color-blue-filter;
  border-radius: 4px;
  box-sizing: border-box;
  color: $color-blue-filter;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 40px;
  margin-right: 8px;
  padding: 10px;

  .remove-icon{
    align-self: flex-start;
    cursor: pointer;
  }
}