@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.sort-icon {
    @include flex-column-start-center;
    padding-left: 12px;
    cursor: pointer;
    .rotate {
        transform: rotate(180deg);
        margin-bottom: 4px;
    }
}
  