@import './../../assets/css/colors.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/variables.scss';


.dropdown-container{
  align-items: flex-end;
  background: $color-white;
  border-radius: 8px;
  border-style : none;
  display: flex;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.2));
  flex-direction: column;
  padding: 0px;
  position: absolute;
  width: 158px;
}