@import '../../assets/css/colors.scss';

.ad-preview {
  align-items: center;
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 374px;
  padding: 10px 12px;
  width: 288px;
  .ad-profile-info-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .ad-text-wrapper {
    color: $color-dark-blue;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .ad-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 250px;
    flex-direction: column;
    .ad-image {
      width: 100%;
      height: 240px;
      border-radius: 16px;
    }
    .ad-video {
      width: 100%;
      height: 240px;
      border-radius: 16px;
      object-fit: initial;
    }
    .ad-image-footer {
      position: relative;
      bottom: 43px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 16px;
      padding: 8px 10px;
      background-color: $color-dark-blue;
      .ad-footer-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .ad-footer-action-button {
          cursor: pointer;
          .action-button-label {
            color: $color-white;
            font-size: 16px;
            text-decoration: underline;
            margin-right: 10px;
          }
        }
      }
      .ad-footer-left {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        .ad-footer-title {
          color: $color-white;
          font-size: 16px;
        }
        .ad-footer-url {
          color: $color-white;
          font-size: 13px;
          opacity: 0.8;
        }
      }
    }
  }
  .location-wrapper {
    position: relative;
    display: flex;
    width: 90%;
    flex-wrap: nowrap;
    padding: 0 20px;
    align-items: center;
    bottom: 8%;
    .ad-preview-location {
      margin-left: 5px;
      font-size: 13px;
      color: $color-gray-silver;
    }
  }
}
