.carousel-component {
  max-width: 269px;
  .content-image {
    border-radius: 16px;
  }
  .dots-container {
    max-width: 269px;
    padding: 0 10px;
    position: relative;
    bottom: 30px;
    display: flex;
    justify-content: center;
    .dot-item {
      cursor: pointer;
      margin: 0 3px;
    }
  }
}
