@import '../../assets/css/colors.scss';

.post-preview {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 269px;
  .post-text-wrapper {
    padding: 0 5px;
    .post-text {
      color: $color-dark-blue;
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  .post-images {
    width: 269px;
    height: 269px;
  }
}
