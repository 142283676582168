@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
@import '../../../../assets/css/variables.scss';
.container {
  display: flex;
  margin: 20px;

  .container-image {
    margin-right: 10px;

    .image {
      border-radius: 14px;
      cursor: pointer;
      height: 25px;
      width: 25px;
    }
  }

  .container-description-comment {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 420px;

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
