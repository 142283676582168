@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.task-no-results-container{
  @include flex-column-center;
  height: calc(100vh - 209.53px);

  .task-no-results-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $color-gray;
    text-align: center;
  }
}