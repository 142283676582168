@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.loading {
  @include flex-column-center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: $color-black;
  opacity: 0.4;
}
