.comment-preview-component {
  display: flex;
  justify-content: center;
  .comment-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    max-width: 590px;
    &.replies {
      position: relative;
      left: 8%;
    }
  }
}
