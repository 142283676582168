//TODO: 25px=0.85em 16px=0.65 - 13px = 0.55em
@import '../../assets/css/colors.scss';

.deal-preview-component {
  align-items: center;
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: $color-dark-blue;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 337px;
  width: 193px;
  .deal-preview-header {
    align-items: center;
    display: flex;
    height: 72px;
    padding: 8px 10px;
    width: 100%;
    .deal-screen-name {
      align-items: center;
      display: flex;
      font-size: 0.85em;
      font-weight: 600;
      justify-content: center;
      margin-right: 16px;
      width: 100%;
    }
    .left-icon-wrapper {
      margin-left: 8px;
    }
  }
  .deal-preview-file-wrapper {
    max-height: 140px;
    width: 100%;
    .deal-offer-tag {
      background-color: $color-red-caramine;
      border-radius: 4px;
      bottom: 75%;
      bottom: 75%;
      color: $color-white;
      font-size: 0.75em;
      font-weight: 600;
      left: 10px;
      left: 10px;
      padding: 2px;
      position: relative;
    }
    .deal-preview-image {
      left: 0;
      max-height: 140px;
      position: relative;
      top: 0;
      width: 193px;
      z-index: 0;
    }
  }

  .deal-preview-info {
    background-color: $color-white;
    border-radius: 16px 16px 0px 0px;
    bottom: 15%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    left: 0;
    padding: 0 10px;
    position: relative;
    width: 175px;
    .deal-preview-date-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 4px 0;
      .deal-preview-date {
        color: $color-gray-silver;
        font-size: 13px;
        margin-left: 5px;
      }
    }
  }

  .deal-preview-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    .deal-preview-title {
      font-size: 0.85em;
      font-weight: 600;
    }
    .deal-preview-business {
      color: $color-gray-silver;
      font-size: 0.75em;
      font-weight: 600;
    }
    .deal-preview-location {
      align-items: center;
      display: flex;
      .deal-preview-location-text {
        color: $color-gray-silver;
        font-size: 0.55em;
        font-weight: 400;
        text-decoration: underline;
      }
    }
    .preview-buttons {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }

  .deal-preview-footer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    .offer-details {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .offer-detail-title {
        font-size: 0.65em;
      }
    }
    .deal-condition-list {
      list-style: square;
      margin: 0;
      padding: 0 10px 5px;
      width: 100%;
      .deal-condition-item {
        font-size: 0.55em;
      }
    }
  }

  .separator {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    width: 100%;
  }
}
