@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';
@import '../../assets/css/variables.scss';

.communities-form {
  display: flex;
  flex-direction: column;

  .communities-wrapper {
    margin-top: 16px;

    .community-container {
      display: flex;
      border-radius: 16px;
      border: 1px solid $color-gray-silver;
      cursor: pointer;
      padding: 8px 16px;

      &.selected-community {
        border: 1px solid $color-blue-dodger;
        box-shadow: 0px 9px 13px rgba(10, 114, 237, 0.13);

        .radio .radius-selector-content {
          background: $color-blue-dodger !important;
        }
      }

      .option-radio {
        align-items: center;
        display: flex;

        .radio {
          align-items: center;
          border: 1px solid $color-blue-dodger;
          border-radius: 50%;
          display: flex;
          height: 16px;
          justify-content: center;
          margin-right: 15px;
          padding: 2px;
          width: 16px;

          .radius-selector-content {
            background: transparent;
            border-radius: 50%;
            display: flex;
            width: 100%;
            height: 100%;
          }
        }

        .content-radio {
          display: flex;
          flex-direction: column;

          .title {
            color: $color-dark-blue;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            margin-right: 12px;
          }

          .city {
            color: $color-dark-blue;
            font-size: 13px;
            font-weight: 600;
            line-height: 19px;
            opacity: 0.7;
          }
        }
      }
    }
  }
}
