@import '../../assets/css/colors.scss';

.loading-process-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .process-text {
    display: flex;
    font-weight: 600;
    color: $color-blue-title-content-header;
    font-size: 36px;
    line-height: 60px;
    margin-top: 25px;

    &.completed {
      margin-top: inherit;
    }
  }

  .process-action-btn {
    .rounded-button {
      height: 32px;
      width: 80px;
      border: 1px solid $color-blue-link;
      color: $color-blue-link;
    }
  }
}
