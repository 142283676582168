@import '../../assets/css/colors.scss';

%content-flex-colum {
  display: flex;
  flex-direction: column;
}

.verify-images-wrapper {
  @extend %content-flex-colum;
  margin-top: 45px;

  .title {
    color: $color-dark-blue;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
  }

  .first-section-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .profile-image-content {
      @extend %content-flex-colum;
      margin-right: 54.2px;

      .profile-img {
        border-radius: 12px;
        height: 160px;
        width: 162px;
      }
    }

    .banner-image-content {
      @extend %content-flex-colum;

      .banner-img {
        border-radius: 12px;
        height: 160px;
        width: 459px;
      }
    }
  }

  .second-section-wrapper {
    margin-top: 30px;

    .business-images {
      @extend %content-flex-colum;

      .images-content {
        .business-img {
          border-radius: 12px;
          height: 137px;
          margin-right: 21px;
          width: 139px;
        }
      }
    }
  }
}
