@import './../../assets/css/colors.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/variables.scss';

.rotate-left {
    transform: rotate(90deg);
    margin-bottom: 2px;
}
.rotate-right {
    transform: rotate(270deg);
    margin-bottom: 2px;
}