@import '../../assets/css/variables.scss';
@import '../../assets/css/colors.scss';

.indicators-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;

  .card {
    background: $color-white;
    border-radius: 8px;
    box-shadow: 0px 11px 21px rgba(57, 73, 84, 0.06);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 86px;
    margin-right: 0.8%;
    width: 214px;

    .indicator {
      display: flex;
      margin-left: 28px;
      margin-top: 14px;

      .number {
        color: $color-blue-title-content-header;
        font-family: $app-font-name;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        margin-left: 20px;
      }
    }

    .text {
      color: $color-blue-title-content-header;
      font-size: 16px;
      line-height: 24px;
      margin-left: 28px;
    }
  }
}
