$color-black-dark: #1c1c1c;
$color-black-light: #242629;
$color-black-link: #020202;
$color-black-rgba: rgba(0, 0, 0, 0.15);
$color-black: #000000;

$color-white-cultured: #f6f6f6;
$color-white-opacity-40: rgba(255, 255, 255, 0.4);
$color-white: #ffffff;

$color-blue-arsenic: #3b3b4a;
$color-blue-cian-light-shadow: #a5c9e2;
$color-blue-cornflower-rgba: rgba(93, 159, 234, 0.15);
$color-blue-cornflower: #5d9fea;
$color-blue-cursor-blink: #1a3866;
$color-blue-dodger: #1b7aee;
$color-blue-filter: #0078ff;
$color-blue-info: #e7f1fc;
$color-blue-link: #0a72ed;
$color-blue-reply: #0a72ed;
$color-blue-selago: #f4f7fe;
$color-blue-table-actions-active-button: #84b8f6;
$color-blue-tabs: #0f63c9;
$color-blue-title-content-header: #394954;
$color-dark-blue-rgba-15: rgba(23, 23, 32, 0.15);
$color-dark-blue-rgba: rgba(23, 23, 32, 0.7);
$color-dark-blue: #171720;

$color-gray-alabaster: #f8f8f8;
$color-gray-background: #e5e5e5;
$color-gray-blue-ellipses-btn: #edf5fe;
$color-gray-description-modal: #4d4d4d;
$color-gray-ghost: #d1d1d2;
$color-gray-input-search: #848991;
$color-gray-light-opacity-70: rgba(237, 237, 237, 0.7);
$color-gray-light-opacity: rgba(237, 237, 237, 0.4);
$color-gray-light: #ededed;
$color-gray-silver-dark: #7d7d7d;
$color-gray-silver: #747479;
$color-gray-tuna: #3b3b4a;
$color-gray: #9c9c9c;

$color-dark-red-shade: #7d7d7d;
$color-red-caramine: #de4842;
$color-red-linen: #f9e4e2;
$color-red-pink: #ff0050;

$color-green-mint-rgba: rgba(69, 185, 129, 0.15);
$color-green-mint: #45b981;
$color-green-narvik: #e3f5ec;
$color-green-success: #36b37e;

$color-orange-linen: #f9eae0;
$color-orange-marigold-rgba: rgba(243, 166, 35, 0.1);
$color-orange-marigold: #f3a623;
$color-orange-tangerine: #f98600;

$color-pink-commerce: #ff0050;
$color-pink-rgba: rgba(233, 101, 162, 0.15);
$color-pink: #e965a2;

$color-violet-lavender-rgba: rgba(162, 126, 225, 0.15);
$color-violet-lavender: #a27ee1;
$color-violet-mana-culture: '#C828B4';
