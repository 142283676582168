@import '../../assets/css/colors.scss';

.mana-post-news-preview-wrapper {
  display: flex;
  flex-direction: column;

  .card-preview-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .card-header {
      -moz-box-shadow: 3px -1px 16px -3px $color-black-rgba;
      -webkit-box-shadow: 3px -1px 16px -3px $color-black-rgba;
      align-items: center;
      box-shadow: 3px -1px 16px -3px $color-black-rgba;
      display: flex;
      padding: 12px 10px;

      img {
        border-radius: 50%;
        height: 50.8px;
        margin-right: 11px;
        object-fit: cover;
        width: 50px;
      }

      .user-post {
        display: flex;
        flex-direction: column;

        .text-user {
          color: $color-dark-blue;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .time-posted {
          color: $color-gray-silver;
          font-size: 13px;
          font-weight: 400;
          line-height: 15px;
        }
      }
    }

    .card-body {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 230px;
      max-width: 414px;

      &-multimedia-video {
        height: 259px;
        object-fit: initial;
        width: 100%;
      }
    }

    .card-footer {
      -moz-box-shadow: 3px 0px 16px 4px $color-black-rgba;
      -webkit-box-shadow: 3px 0px 16px 4px $color-black-rgba;
      box-shadow: 3px 0px 16px 4px $color-black-rgba;
      display: flex;
      flex-direction: column;
      padding: 10px 15px;

      .text {
        color: $color-dark-blue;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }

      a {
        color: $color-blue-link;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        text-decoration: none;

        &.not-link {
          cursor: not-allowed;
        }
      }
    }
  }

  .buttons-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;

    .link-button {
      color: $color-black-link !important;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-decoration-line: underline;
    }

    .rounded-button {
      height: 30px;
      margin-left: 10px;
      width: 100px;
    }
  }
}
