@import '../../assets/css/colors.scss';

.page-content-dashboard {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100vh;
  overflow-y: auto;
  padding: 0px 15px;
}
