@import '../../../../../assets/css/colors.scss';

.login-error-message {
  display: flex;
  align-items: center;
  margin: 16px 0;
  .error-text {
    font-size: 16px;
    color: $color-red-caramine;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}
