@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.verification-code-page {
  @include login-page;

  .code-input-error {
    padding: 1.5px;
    background: $color-pink-commerce;
    border-radius: 8px;
  }

  .character--inactive {
    background-color: $color-white;
  }

  .character--selected {
    color: $color-black;
    outline: 0px;
    position: relative;

    &:empty::after {
      animation: blink 1s infinite step-end;
      background-color: $color-blue-cursor-blink;
      content: '';
      display: block;
      height: 30px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
    }

    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
  }

  .character-error {
    border: 1px solid $color-pink-commerce;
  }

  .link-button {
    color: $color-black-light;
    margin-top: 30px !important;
    margin-bottom: 30px;
  }
}
