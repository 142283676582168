@import '../../../../assets/css/mixins.scss';

.forgot-password-form {
  @include flex-column-center;
  width: 100%;

  .bottom-container {
    margin-bottom: 52px;
  }
}
