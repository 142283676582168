@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.profile-verification {
  background-color: $color-blue-selago;
  border-radius: 24px 24px 0px 0px;
  .top-container {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    padding: 20px 20px 0 0;
    .close-button {
      cursor: pointer;
      width: 11px;
      height: 11px;
      background: url('../../assets/icons/close.svg') no-repeat center;
      //TODO: Implement hover here.
    }
  }

  .verification-header {
    @include flex-column-nowrap;
    max-height: 224px;
    color: $color-blue-tabs;
    padding: 0 32px;
    margin-bottom: 35px;

    .title {
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 52px;
      .middot {
        margin-left: 12px;
        margin-right: 12px;
      }
    }

    .role-name {
      height: 32px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      line-height: 24px;
      &.mana-member {
        color: $color-blue-filter;
      }
      &.mana-vendor {
        color: $color-green-success;
      }
      &.mana-invest {
        color: $color-orange-tangerine;
      }
      &.vendor {
        color: $color-pink;
      }
      &.mana-partner {
        color: $color-violet-mana-culture;
      }
    }
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-dark-blue;
    }
  }

  .panel {
    background-color: $color-white;
  }

  .verification-footer {
    @include flex-row-center;
    height: 104px;
    background-color: $color-white;
    padding: 0 29px;

    .side {
      @include flex-row-center;
      width: 50%;
      &.start {
        justify-content: flex-start;
      }
      &.end {
        justify-content: flex-end;
      }
    }
  }
}

.tab-list {
  margin: 0px;
  padding: 0 32px;

  .tab {
    svg path {
      stroke: $color-blue-filter;
    }
  }
}
