@import '../../../../assets/css/colors.scss';

.map-container {
  background-color: $color-dark-blue;
  border-radius: 10px;
  display: flex;
  height: 160px;
  width: 100%;

  a[target='_blank'],
  div.gmnoprint,
  div.gm-style-cc {
    display: none!important;
  }
}