@import './colors.scss';

@mixin flex-column-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

@mixin flex-column-start-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

@mixin flex-column-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-row-start-center {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
}
@mixin flex-row-center {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}
@mixin flex-row-screen-center {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@mixin flex-row-screen-end {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

@mixin flex-row-start {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

@mixin flex-row-center-space-between {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;
}

@mixin input-autofull {
  -webkit-box-shadow: 0 0 0px 1000px $color-gray-light inset;
  border: 1px solid $color-dark-blue !important;
  box-shadow: 0 0 0px 1000px $color-gray-light inset;
}

@mixin page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 26px 32px 26px 29px;
}

@mixin login-page {
  @include flex-column-start-center;
  background-color: $color-dark-blue;
  height: 100%;
  width: 100%;
}
