@import '../../../../assets/css/colors.scss';
.comment-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px;
  .comment-profile {
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
  }
  .comment-text {
    color: $color-dark-blue;
    font-size: 16px;
    margin-left: 35px;
  }
  .is-reported {
    position: relative;
    top: 10px;
    left: 10px;
  }
}
