@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.profile-info {
  @include flex-row-center;
  margin: 0px 26px;
  .user-info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: $color-white;
    margin-left: 12px;
    margin-right: 12px;

    .user-full-name {
      font-size: 13px;
      font-weight: 600;
      line-height: 24px;
    }
    .user-email {
      font-size: 10px;
      font-weight: 400;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }
  .profile-image {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}
