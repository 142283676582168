@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.rounded-button {
  @include flex-row-screen-center;
  cursor: pointer;
  height: 52px;
  color: $color-dark-blue;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid $color-dark-blue;
  font-size: 16px;
  padding: 0.35em 1.2em;
  white-space: nowrap;
  &.white {
    color: $color-dark-blue;
    background-color: $color-white;
  }
  &.black {
    color: $color-white;
    background-color: $color-dark-blue;
  }
  &.blue {
    color: $color-white;
    background-color: #0078ff;
    border: 1px solid #0078ff;
  }
  &.red-outlined {
    border: 1px solid #de4842;
    color: #de4842;
  }
  &.blue-outlined {
    border: 1px solid #0078ff;
    color: #0078ff;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}
