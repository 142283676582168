@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.user-page {
    @include flex-row-start-center;
    overflow: hidden;
    overflow-x: hidden;
    background-color: $color-gray-alabaster;

    .page-content {
        @include page-content;
    }
}