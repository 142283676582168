@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.text-area-field {
  .label {
    font-size: 16px;
    color: $color-dark-blue;
  }
  .text-area-field-input {
    align-items: flex-start;
    border: 1px solid $color-gray-silver;
    box-sizing: border-box;
    color: $color-dark-blue;
    display: flex;
    flex-direction: row;
    font-family: $app-font-name;
    font-size: 16px;
    gap: 12px;
    height: 103px;
    outline-color: $color-gray-silver;
    padding: 8px 8px 10px;
    resize: none;
    width: 100%;
  }
}
