@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';
@import '../../assets/css/variables.scss';

.confirmation-modal {
  @include flex-column-start-center;
  max-width: 447px;
  max-height: 332px;
  padding: 0 36px;
  .icon {
    margin-bottom: 0px;
  }

  .question {
    font-size: 28px;
    font-weight: bold;
    color: $color-dark-blue;
  }

  .question-detail {
    color: $color-gray-silver;
    white-space: pre-line;
    margin-top: 8px;
    text-align: center;
    padding: 0 45px;
  }

  .top-container {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    padding: 22.75px 0px 0px 22.99px;
    width: calc(100% - 40px);
    margin-left: 45px;
    .close-button {
      cursor: pointer;
      width: 14.01px;
      height: 13.51px;
      background: url('../../assets/icons/close.svg') no-repeat center;
    }
  }

  .confirmation-buttons {
    @include flex-row-screen-center;
    width: 100%;
    margin: 40px 0 52px 0;
  }
}
