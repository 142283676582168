@import '../../../assets/css/mixins.scss';
@import '../../../assets/css/variables.scss';
@import '../../../assets/css/colors.scss';

.title-content{
  color: $color-blue-title-content-header;
  font-family: $app-font-name;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
}