@import '../../assets/css/colors.scss';
@import '../../assets/css/mixins.scss';

.link-button {
  @include flex-row-center;
  color: $color-dark-blue;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  height: 52px;
  line-height: 18px;
  text-decoration: underline;
}
