@import './../../assets/css/colors.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/variables.scss';

.table-fixed-head {
  border-collapse: collapse;
  overflow-x: auto;
  overflow-y: hidden;
  table-layout: fixed;
  text-align: -webkit-center;
  width: 100%;
}
.table {
  border-spacing: 0px;
  min-width: 610px;
}
.table-fixed-head tbody {
  display: block;
  height: calc(100vh - 350.55px);
  overflow: auto;
  width: 100%;
}
.table-fixed-head thead tr {
  display: block;
}
.table-fixed-head th,
.table-fixed-head td {
  width: 300px;
}

::-webkit-scrollbar {
  height: 5px;
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
