@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
.table-buttons {
  @include flex-row-screen-center;
  position: relative;
  color: $color-blue-link;
  .community-wraper {
    white-space: nowrap;
  }
  .community-selector {
    @include flex-row-center;
    cursor: pointer;
    margin-left: 26px;
  }
  .chevron {
    @include flex-row-center;
  }
}

.dropdown-item {
  padding: 12px 32px 12px 0px;
  margin: 0;
  font-weight: normal;
  width: 126px;
  color: $color-blue-link;
  z-index: 7;
  text-align: end;
  cursor: pointer;
  &:hover {
    background: $color-gray-light;
  }
}

.dropdown-item-green {
  color: $color-green-success;
}

.dropdown-item-red {
  color: $color-red-caramine;
}
