@import './../../assets/css/colors.scss';
@import './../../assets/css/mixins.scss';
@import './../../assets/css/variables.scss';

.tag-text {
    @include flex-row-screen-center;
    width: 110px;
    height: 40px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}