@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';
.tbody-tr-background-white {
    background-color: $color-white;
}

.tbody-tr {
    height: 80px;
    td {
        border-bottom: 1px solid $color-gray-ghost;
        word-break: break-all;
    }
    .tbody-td-first-item {
        color: $color-dark-blue;
        padding-left: 16px;
        text-align: start;
    }
    .tbody-td-center-item {
        text-align: -webkit-center;
    }
    .tbody-td-center-button-delete {
        display: flex;
        justify-content: center;
    }
}