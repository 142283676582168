@import '../../assets/css/colors.scss';

.wide-button {
  align-items: center;
  background-color: $color-dark-blue;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  height: 52px;
  justify-content: center;
  width: 322px;

  &:hover {
    background-color: $color-blue-arsenic;
  }

  &.disabled {
    background-color: $color-dark-blue-rgba-15;
    cursor: not-allowed;
  }

  span {
    color: $color-white;
    font-size: 16px;
    font-weight: normal;
  }
}
