.iframe-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;

  .iframe-content {
    border: none;
    display: flex;
    height: 100vh;
    width: 100%;
  }
}
