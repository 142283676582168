@import '../../assets/css/colors.scss';

.profile-name-component {
  display: flex;
  align-items: center;
  .img-profile {
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
  .profile-right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 10px;

    .profile-full-name {
      font-weight: 600;
      color: $color-dark-blue;
      font-size: 16px;
    }
    .profile-full-tag {
      color: $color-gray-silver;
      font-size: 13px;
    }
  }
}
