@import '../../../assets/css/mixins.scss';
@import '../../../assets/css/variables.scss';
@import '../../../assets/css/colors.scss';

.filter-content{

  .filter-container{

    @include flex-row-screen-center;
    cursor: pointer;
    margin-right: 20px;

    .text{
      font-family: $app-font-name;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-top: -5px;
    }

    .active{
      color: $color-blue-filter;
    }
    .inactive{
      color: $color-blue-title-content-header;
    }

    .activeIcon{
      path{
        fill: $color-blue-filter;
      }
    }
    .inactiveIcon{
      path{
        fill: $color-blue-title-content-header;
      }
    }
  }

}