@import '../../assets/css/colors.scss';

.select-component {
  .select-label {
    align-items: center;
    color: $color-dark-blue;
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .option-item-container {
    cursor: pointer;
    width: 100%;

    &:hover {
      background: $color-gray-light;
      color: $color-dark-blue;
    }

    .option-item {
      align-items: center;
      border-bottom: 1px solid $color-gray-light;
      color: $color-gray-silver;
      display: flex;
      font-size: 16px;
      height: 35px;
      justify-content: space-between;
      margin-left: 8px;
      margin-right: 8px;
      padding: 8px 0;

      &.selected {
        color: $color-dark-blue;
        transform: matrix(1, 0, 0, 1, 0, 0);
        border: none;
      }
    }
  }
}
