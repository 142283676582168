@import '../../../../assets/css/mixins.scss';
@import '../../../../assets/css/colors.scss';

.head {
  height: 48px;
  border-radius: 8px;
  background-color: $color-gray-light;
  @include flex-row-screen-center;
  th {
    position: sticky;
    word-break: break-all;
  }
  .head-item{
    padding-top: 11px;
    @include flex-row-screen-center;
  }
  .head-item-start {
    padding-top: 11px;
    padding-left: 16px;
    @include flex-row-start;
  }
  .head-item-label{
    cursor: pointer;
  }
}
