@import '../../../../assets/css/colors.scss';

.mana-post-news-form-wrapper {
  display: flex;
  flex-direction: column;

  .field-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;

    & .align-start {
      align-self: flex-start;
    }

    .text-label {
      color: $color-dark-blue-rgba;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      width: calc((30% - 10px) / 1);
    }

    .value-field-wrapper {
      display: flex;
      flex-direction: column;
      width: calc((70% - 10px) / 1);

      > :first-child {
        width: 100%;
      }

      > .text-field {
        input {
          width: inherit;
        }
      }

      > .dropzone-div {
        width: initial;
      }

      .error-message-wrapper {
        margin-top: -25px;
      }
    }
  }
}
