@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

.table-buttons {
  @include flex-row-screen-center;
  position: relative;

  .elipsis-icon-action-list {
    @include flex-column-center;
    background: $color-gray-blue-ellipses-btn;
    border-radius: 24px;
    border: none;
    cursor: pointer;
    gap: 10px;
    height: 28px;
    margin-left: inherit;
    padding: 8px;
    transform: rotate(90deg);
    width: 28px;

    &.active {
      background: $color-blue-table-actions-active-button;
    }

    &:hover {
      background: $color-gray-light;
    }

    &:active {
      background: $color-blue-table-actions-active-button;
    }
  }
}
