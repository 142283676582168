@import '../../assets/css/mixins.scss';
@import '../../assets/css/colors.scss';

:root {
  --bg-main: $color-white;
  --bg-right: $color-white;
  --header-height: 4rem;
  --landscape-width: 25; // % OR vw for responsive window resizing!
  --portrait-width: 80vw;
  --secs: 0.4s;
}

#layout {
  display: flex;
  overflow: hidden;
  .left-menu-logo {
    display: flex;
    justify-content: center;
    .logo-container {
      margin-top: 40px;
    }
  }
  .left-menu-bottom {
    @include flex-column-nowrap;
    bottom: 140px;
    color: $color-white-opacity-40;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    width: 100%;
    .logout {
      @include flex-row-center;
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-left: 25px;
      margin-top: 32px;
      width: 80px;

      .logout-button-text {
        padding-left: 10.5px;
      }
      path {
        opacity: 0.4;
      }
    }
  }
}

// Style in common for both sidebars:
#left {
  width: 0;
  position: relative;
  height: 100%;
  @media (orientation: landscape) {
    &.open {
      width: 265px;
      transition: width var(--secs);
    }
    &.closed {
      width: 97px;
      transition: width var(--secs);
    }
  }
  .sidebar {
    transition: transform var(--secs); // <-- Portrait AND landscape!

    @media (orientation: landscape) {
      &.open {
        width: 265px;
        transition: width var(--secs);
      }
      &.closed {
        width: 97px;
        transition: width var(--secs);
      }
    }
  }
}

// Left sidebar specific style:
#left {
  z-index: 5;
  .sidebar {
    background: $color-blue-title-content-header;
    .header {
      left: var(--header-height);
    }
  }
}
