@import '../../assets/css/mixins.scss';
@import '../../assets/css/variables.scss';
@import '../../assets/css/colors.scss';

.task-manager-page {

  @include flex-row-start-center;
  overflow: hidden;
  overflow-x: hidden;
  background-color: $color-gray-alabaster;
  
  .page-content {
    @include page-content;
    height: 100vh;
  }
}
.tag-container {
  @include flex-row-screen-center;
  margin-right: -52px;
}